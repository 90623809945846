<template>
    <v-container fill-height fluid text-xs-center>
        <v-card class="fill-height container--fluid" width="100vw" flat outlined>
            <v-data-table
                :headers="headers"
                :items="events"
                :items-per-page="10"
                :search="searchEvent"
                :loading="loadingData"
                :expanded="expanded"
                single-expand
                show-expand
                item-key="id"
                sort-by="id"
                disable-sort
                no-data-text="No hay datos para mostrar..."
                loading-text="Cargando los datos, por favor espere."
                class="elevation-1"
                :footer-props="{
                        showFirstLastPage: true,
                        disableItemsPerPage: false,
                        itemsPerPageAllText: 'Todas',
                        itemsPerPageText: 'Filas por página:'
                    }">
                <template v-slot:top>
                    <v-toolbar flat class="align-center">
                        <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'text-subtitle-1 text-md-h5' : ''">
                            <span>Cat&aacute;logo de Eventos</span>
                        </v-toolbar-title>
                        <v-divider class="mx-4" inset vertical></v-divider>
                        <v-spacer></v-spacer>
                        <v-responsive max-width="300" height="45" class="hidden-sm-and-down">
                            <v-text-field
                                id="findtext-project"
                                label="Buscar..."
                                name="findproject"
                                outlined
                                dense
                                single-line
                                clearable
                                background-color="white"
                                hide-details
                                v-model="searchEvent"
                                class="white--text mt-0 pt-0 mr-1"
                                prepend-inner-icon="mdi-magnify"
                            ></v-text-field>
                        </v-responsive>
                        <v-btn @click="getEvents"
                               :loading="loadingData"
                               elevation="0"
                               :small="$vuetify.breakpoint.smAndDown"
                               class="mb-2 mr-2"
                        >
                          <v-icon>mdi-reload</v-icon>
                        </v-btn>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialog"
                            max-width="1000px"
                            persistent
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn :small="$vuetify.breakpoint.smAndDown"
                                       color="primary caption"
                                       dark
                                       depressed
                                       elevation="0"
                                       class="mb-2"
                                       v-bind="attrs"
                                       :loading="loadingData"
                                       v-on="on"
                                       @click="newItem">
                                    Adicionar
                                </v-btn>
                            </template>
                            <v-card>
                                <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation>
                                    <v-card-title class="text-h6 primary">
                                        <span class="white--text headline">{{ formTitle }}</span>
                                    </v-card-title>
                                    <v-card-text>
                                        <v-container class="my-0 py-0">
                                            <v-row dense class="mt-0 pt-0">
                                                <v-col cols="12" md="6">
                                                    <v-container>
                                                        <v-row dense>
                                                            <v-col cols="12">
                                                                <v-text-field
                                                                    dense
                                                                    v-model="editedItem.name"
                                                                    label="Nombre"
                                                                    :rules="textRules"
                                                                    :disabled="loadingSave"
                                                                    required>
                                                                </v-text-field>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-textarea
                                                                    dense
                                                                    v-model="editedItem.description"
                                                                    label="Descripción"
                                                                    :rules="textRules"
                                                                    :disabled="loadingSave"
                                                                    no-resize
                                                                    rows="3"
                                                                    counter
                                                                    required>
                                                                </v-textarea>
                                                            </v-col>
                                                            <v-col cols="12">
                                                                <v-combobox
                                                                    dense
                                                                    v-model="editedItem.location"
                                                                    :items="places"
                                                                    :disabled="loadingSave"
                                                                    :loading="loadingPlaces"
                                                                    :search-input.sync="searchQuery"
                                                                    :rules="[v => !!v || 'La dirección de ubicación obligatoria']"
                                                                    no-data-text="No hay resultados de búsqueda"
                                                                    item-text="place_name"
                                                                    no-filter
                                                                    clearable
                                                                    required
                                                                    label="Ubicación"
                                                                    v-on="$listeners"
                                                                    v-on:change="getSelectedItem"
                                                                    @click:clear="onClear">
                                                                </v-combobox>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-container>
                                                        <v-row dense>
                                                            <v-col cols="12">
                                                                <v-row no-gutters align="center" justify="center">
                                                                    <v-img
                                                                        :src="imagePreview != null ? imagePreview : require('@/assets/images/no-image.jpg')"
                                                                        :lazy-src="require('@/assets/images/no-image.jpg')"
                                                                        max-height="200"
                                                                        max-width="auto"
                                                                        :aspect-ratio="1"
                                                                        :disabled="loadingSave"
                                                                        class="white--text align-center justify-center elevation-2">
                                                                    </v-img>
                                                                </v-row>
                                                                <v-row no-gutters align="center" justify="center">
                                                                    <v-file-input
                                                                        v-model="imageFile"
                                                                        accept="image/png, image/jpeg, image/bmp"
                                                                        show-size
                                                                        clearable
                                                                        :disabled="loadingSave"
                                                                        :rules="imageRules"
                                                                        label="Cargar/Cambiar imagen"
                                                                        placeholder="Archivo de imagen"
                                                                        prepend-icon="mdi-camera"
                                                                        @change="changePreviewImage()">
                                                                    </v-file-input>
                                                                </v-row>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-col>
                                            </v-row>
                                            <v-row dense>
                                                <v-col cols="12" md="6">
                                                    <v-menu
                                                        v-model="menu_date1"
                                                        :disabled="loadingSave"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                :value="computedStartDateFormatted"
                                                                clearable
                                                                label="Fecha de inicio"
                                                                readonly
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click:clear="editedItem.start_date = null">
                                                            </v-text-field>
                                                            <!--@blur="editedItem.finish_date = parseDate(editedItem.finish_date)"-->
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.start_date"
                                                            no-title
                                                            :disabled="loadingSave"
                                                            color="orange"
                                                            locale="es-ES"
                                                            @change="menu_date1 = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-menu
                                                        v-model="menu_date2"
                                                        :close-on-content-click="false"
                                                        transition="scale-transition"
                                                        offset-y
                                                        :disabled="loadingSave"
                                                        max-width="290">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field
                                                                dense
                                                                :value="computedFinishDateFormatted"
                                                                clearable
                                                                label="Fecha estimada de terminación"
                                                                readonly
                                                                :disabled="loadingSave"
                                                                prepend-icon="mdi-calendar"
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                @click:clear="editedItem.finish_date = null">
                                                            </v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="editedItem.finish_date"
                                                            no-title
                                                            :disabled="loadingSave"
                                                            color="orange"
                                                            locale="es-ES"
                                                            @change="menu_date2 = false"
                                                        ></v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field-money
                                                        v-model.number="editedItem.investment_amount"
                                                        label="Monto total de la Inversión"
                                                        :disabled="loadingSave"
                                                        v-bind:properties="{
                                                            dense: true,
                                                            prefix: '$',
                                                            suffix: 'MXN',
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                                        v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 11,
                                                        precision: 2,
                                                        empty: 0,
                                                        required: false
                                                      }"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field-money
                                                        v-model.number="editedItem.investment_income"
                                                        label="Ingreso Estimado"
                                                        :disabled="loadingSave"
                                                        v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                suffix: 'MXN',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                        v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: 0,
                                                            required: false
                                                          }"
                                                        />
                                                </v-col>
                                                <v-col cols="12" md="4">
                                                    <v-text-field-money
                                                        v-model.number="editedItem.expense"
                                                        label="Gasto Planificado"
                                                        :disabled="loadingSave"
                                                        v-bind:properties="{
                                                                dense: true,
                                                                prefix: '$',
                                                                suffix: 'MXN',
                                                                readonly: false,
                                                                disabled: false,
                                                                outlined: false,
                                                                clearable: false,
                                                                placeholder: '',
                                                              }"
                                                        v-bind:options="{
                                                            locale: 'es-MX',
                                                            length: 11,
                                                            precision: 2,
                                                            empty: 0,
                                                            required: false
                                                          }"
                                                    />
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-text-field
                                                        dense
                                                        v-model="editedItem.youtube_video"
                                                        :disabled="loadingSave"
                                                        prepend-inner-icon="mdi-youtube"
                                                        label="Canal de Youtube"
                                                    ></v-text-field>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-select
                                                        dense
                                                        v-model="editedItem.status"
                                                        :items="statusList"
                                                        item-text="text"
                                                        item-value="value"
                                                        :disabled="loadingSave"
                                                        label="Estado del evento"
                                                        :rules="[v => !!v || 'El estado del Evento es obligatorio']"
                                                        required
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="12" md="6">
                                                    <v-checkbox
                                                        dense
                                                        :disabled="loadingSave"
                                                        v-model="editedItem.active"
                                                        label="¿Activo?"
                                                        :rules="[v => !!v || 'Debes de marcar Activo para continuar']"
                                                        required
                                                    ></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-container>
                                    </v-card-text>
                                    <v-card-actions class="mt-0 pt-0">
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            :disabled="loadingSave"
                                            @click="close"
                                        >
                                            Cancelar
                                        </v-btn>
                                        <v-btn
                                            :disabled="!valid"
                                            :loading="loadingSave"
                                            depressed
                                            color="primary darken-1 caption"
                                            @click="save"
                                        >
                                            <v-icon dark>mdi-content-save</v-icon>/
                                            Guardar
                                        </v-btn>
                                    </v-card-actions>
                                </v-form>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" width="auto">
                            <v-card>
                                <v-card-title class="text-body-1 headline">
                                    ¿Est&aacute;s seguro que desea eliminar este Evento?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDelete">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="deleteItemConfirm">
                                        Aceptar
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            v-model="dialogError"
                            persistent
                            max-width="500">
                            <v-card>
                                <v-card-title class="text-h6 primary lighten-2">
                                    <span class="headline">Error de Validaci&oacute;n</span>
                                </v-card-title>
                                <v-card-text>
                                    <div class="text-h6 pa-12">{{ messageError }}</div>
                                </v-card-text>
                                <v-card-actions class="justify-center">
                                    <v-btn @click.stop="closeDialogError" depressed class="primary caption">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog
                            :fullscreen="$vuetify.breakpoint.smAndDown"
                            v-model="dialogDocumentsPDF"
                            persistent
                            width="600">
                            <v-card>
                                <v-toolbar dark color="primary">
                                    <v-btn
                                        class="hidden-sm-and-down"
                                        icon
                                        dark
                                        @click="dialogDocumentsPDF = false">
                                        <v-icon>mdi-close</v-icon>
                                    </v-btn>
                                    <v-toolbar-title>Administrador de Documentos PDF</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-items>
                                        <v-btn dark
                                               text
                                               @click="dialogDocumentsPDF = false">
                                            Cerrar
                                        </v-btn>
                                    </v-toolbar-items>
                                </v-toolbar>
                                <v-container fill-height bottom-gradient fluid class="ma-0">
                                    <v-row>
                                        <v-col>
                                            <div class="fill-height">
                                                <v-list dense subheader>
                                                    <v-subheader>Lista de Archivos</v-subheader>
                                                    <v-divider/>
                                                    <v-list-item
                                                        v-for="document in editedItem.events_documents"
                                                        :key="document.id">
                                                        <v-list-item-icon>
                                                            <v-icon color="primary">
                                                                mdi-file-document
                                                            </v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="document.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                        <v-list-item-icon>
                                                            <v-tooltip bottom v-if="document.url != null">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        color="primary"
                                                                        class="ma-0 text--darken-1"
                                                                        elevation="0"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        v-on:click="openPDF(document)">
                                                                        <v-icon>mdi-magnify</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Ver documento</span>
                                                            </v-tooltip>
                                                            <v-tooltip bottom v-if="document.url != null">
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-btn
                                                                        dark
                                                                        icon
                                                                        color="primary"
                                                                        class="ma-0 text--darken-1"
                                                                        elevation="0"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                        v-on:click="deleteDocument(document)">
                                                                        <v-icon>mdi-delete</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <span>Eliminar documento</span>
                                                            </v-tooltip>
                                                        </v-list-item-icon>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                                <div class="container mt-0 pt-0">
                                    <v-file-input v-model="selectedPDFFiles"
                                                  small-chips
                                                  accept=".pdf"
                                                  show-size
                                                  multiple
                                                  :disabled="loadingSave"
                                                  clearable
                                                  label="Agregar ficheros"
                                                  @change="inputPDFChanged()">
                                        <template v-slot:selection="{ index, text }">
                                            <v-chip small text-color="white" color="#295671" close
                                                    @click:close="removeChipPDF(index)">
                                                {{ text }}
                                            </v-chip>
                                        </template>
                                    </v-file-input>
                                    <div v-if="pdfFiles">
                                        <h5>Archivos seleccionados</h5>
                                        <v-chip v-for="(f, i) in pdfFiles" :key="i" class="mr-1">
                                            {{ f.name }}
                                        </v-chip>
                                        <div class="py-3">
                                            <v-btn :loading="loadingSave" color="primary caption" depressed @click="uploadDocuments()"
                                                   :disabled="selectedPDFFiles.length === 0">
                                                Actualizar Lista
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDeleteDocument" max-width="500px">
                            <v-card>
                                <v-card-title class="text-h6 headline">
                                    ¿Desea eliminar este Documento de la Lista?
                                </v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn :disabled="loadingSave" @click="closeDeleteDocument">
                                        Cancelar
                                    </v-btn>
                                    <v-btn :loading="loadingSave" color="primary darken-1 caption" depressed @click="deleteItemConfirmDocument">
                                        Proceder
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-toolbar>
                </template>
                <template v-slot:item.active="{ item }">
                    <v-simple-checkbox
                        v-model="item.active"
                        disabled
                    ></v-simple-checkbox>
                </template>
                <template v-slot:item.start_date="{ item }">
                    <span>{{ formatDate(item.start_date) }}</span>
                </template>
                <template v-slot:item.finish_date="{ item }">
                    <span>{{ formatDate(item.finish_date) }}</span>
                </template>
                <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="editItem(item)">
                                mdi-pencil
                            </v-icon>
                        </template>
                        <span>Editar</span>
                    </v-tooltip>
                    <v-tooltip top v-if="!item.archived">
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="loadListDocuments(item)">
                                mdi-file-document
                            </v-icon>
                        </template>
                        <span>Administrar Documentos PDF</span>
                    </v-tooltip>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon
                                v-bind="attrs"
                                v-on="on"
                                small
                                class="mr-2"
                                @click="deleteItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <span>Eliminar</span>
                    </v-tooltip>
                </template>
                <template v-slot:no-data>
                    <span class="text-h5">No hay registros en el listado</span>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                        <v-container fluid>
                            <v-row justify="start" class="ma-1">
                                <h3>{{ item.name }}</h3>
                            </v-row>
                            <v-row class="ma-1">
                                <v-col cols="12" md="8">
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Descripci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.description }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ubicaci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.location }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha de inicio:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.start_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Fecha estimada de terminaci&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ formatDate(item.finish_date) }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Monto de Inversi&oacute;n:
                                        </v-col>
                                        <v-col>
                                            {{ item.investment_amount | toCurrency }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Ingreso Estimado:
                                        </v-col>
                                        <v-col>
                                            {{ item.investment_income | toCurrency }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Gasto Planificado:
                                        </v-col>
                                        <v-col>
                                            {{ item.expense | toCurrency }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Utilidad Esperada:
                                        </v-col>
                                        <v-col>
                                            {{ item.utility | toCurrency }}
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Canal de Youtube:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.youtube_video !== null">
                                                {{ item.youtube_video }}
                                            </span>
                                            <span v-else>
                                                (ninguno)
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start">
                                        <v-col cols="12" md="4">
                                            Estado del Evento:
                                        </v-col>
                                        <v-col>
                                            <span v-if="item.status === 'started'">
                                                Iniciado
                                            </span>
                                            <span v-else-if="item.status === 'execution'">
                                                En avance
                                            </span>
                                            <span v-else>
                                                Terminado
                                            </span>
                                        </v-col>
                                    </v-row>
                                    <v-row :no-gutters="$vuetify.breakpoint.smAndDown" justify="start" v-if="false">
                                        <v-col>
                                            <div class="fill-height">
                                                <v-expansion-panels tile flat accordion hover>
                                                    <v-expansion-panel>
                                                        <v-expansion-panel-header>
                                                            Documentos PDF
                                                        </v-expansion-panel-header>
                                                        <v-expansion-panel-content>
                                                            <v-list dense subheader>
                                                                <v-subheader>Lista de Archivos</v-subheader>
                                                                <v-divider/>
                                                                <v-list-item
                                                                    v-for="document in item.events_documents"
                                                                    :key="document.id">
                                                                    <v-list-item-icon>
                                                                        <v-icon color="orange">
                                                                            mdi-file-document
                                                                        </v-icon>
                                                                    </v-list-item-icon>
                                                                    <v-list-item-content>
                                                                        <v-list-item-title v-text="document.name"></v-list-item-title>
                                                                    </v-list-item-content>
                                                                    <v-list-item-icon>
                                                                        <v-tooltip bottom v-if="document.url != null">
                                                                            <template v-slot:activator="{ on, attrs }">
                                                                                <v-btn
                                                                                    dark
                                                                                    icon
                                                                                    small
                                                                                    color="orange"
                                                                                    class="ma-0 text--darken-1"
                                                                                    elevation="0"
                                                                                    v-bind="attrs"
                                                                                    v-on="on"
                                                                                    v-on:click="openPDF(document)">
                                                                                    <v-icon>mdi-magnify</v-icon>
                                                                                </v-btn>
                                                                            </template>
                                                                            <span>Ver documento</span>
                                                                        </v-tooltip>
                                                                    </v-list-item-icon>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-expansion-panel-content>
                                                    </v-expansion-panel>
                                                </v-expansion-panels>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col cols="12" md="4">
                                    <v-row justify="center">
                                        <v-img
                                            :src="item.image_url != null ? item.image_url :
                                            require('@/assets/images/no-image.jpg')"
                                            :lazy-src="require('@/assets/images/no-image.jpg')"
                                            height="100%"
                                            width="100%"
                                            aspect-ratio="1"
                                            class="white--text align-center justify-center elevation-2">
                                        </v-img>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </v-container>
                    </td>
                </template>
            </v-data-table>
            <v-snackbar
                v-model="snackBar"
                :timeout="2000">
                {{ snackText }}
                <template v-slot:action="{ attrs }">
                    <v-btn v-bind="attrs"
                           icon
                           @click="snackBar = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-snackbar>
        </v-card>
    </v-container>
</template>

<script>
    import eventService from '@/providers/EventService.js';
    import moment from 'moment';
    
    export default {
        name: "EventModuleComponent",
        data: () => ({
            // MapBox Location
            placeNameLimit: 60,
            selectedPlace: {
                place_name: '',
                center: []
            },
            placesList: [],
            loadingPlaces: false,
            searchQuery: null,
            mapBoxApiKey: process.env.VUE_APP_MAPBOX_API_KEY,
            menu_date1: false,
            menu_date2: false,
            focus: false,
            snackBar: false,
            snackText: '',
            valid: true,
            searchEvent: "",
            loadingData: false,
            loadingSave: false,
            dialog: false,
            dialogDelete: false,
            dialogDeleteDocument: false,
            dialogError: false,
            dialogDocumentsPDF: false,
            // Images files
            imageFile: null,
            imagePreview: null,
            selectedImageIndex: -1,
            messageError: '',
            statusList: [
                {value: 'pending', text: 'Pendiente'},
                {value: 'started', text: 'Iniciado'},
                {value: 'execution', text: 'En ejecución'},
                {value: 'canceled', text: 'Cancelado'},
                {value: 'belated', text: 'Atrasado'},
                {value: 'finished', text: 'Terminado'}
            ],
            selectedPDFFiles: [],
            pdfFiles: [],
            events: [],
            expanded: [],
            headers: [
                {text: '', value: 'data-table-expand', width: 5},
                {text: "Id", value: "id", align: " d-none"}, // para ocultar la columna Id.
                {
                    text: "Nombre del Evento",
                    align: "start",
                    sortable: false,
                    value: "name"
                },
                {text: "Ubicación", value: "location"},
                {text: "Fecha de Inicio", value: "start_date", width: 150},
                {text: "Fecha de Terminación", value: "finish_date", width: 150},
                {text: "Acciones", value: "actions", width: 160, align: "center", sortable: false}
            ],
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio'
            ],
            emailRules: [
                v => !!v || "El Correo Electrónico es obligatorio",
                v => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido."
            ],
            imageRules: [
                value =>
                    !value ||
                    value.size < 2000000 ||
                    "El tamaño de la imagen no debe de sobrepasar los 2 MB"
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                name: "",
                description: "",
                location: "",
                map_lat: 0.0,
                map_lng: 0.0,
                start_date: new Date(Date.now()).toISOString(),
                finish_date: new Date(Date.now()).toISOString(),
                investment_amount: 0,
                investment_income: 0,
                expense: 0,
                utility: 0,
                image_url: "",
                youtube_video: "",
                active: true,
                status: 'pending',
                events_documents: []
            },
            defaultItem: {
                id: 0,
                name: "",
                description: "",
                location: "",
                map_lat: 0.0,
                map_lng: 0.0,
                start_date: new Date(Date.now()).toISOString(),
                finish_date: new Date(Date.now()).toISOString(),
                investment_amount: 0,
                investment_income: 0,
                expense: 0,
                utility: 0,
                image_url: "",
                youtube_video: "",
                active: true,
                status: 'pending',
                events_documents: []
            }
        }),
        filters: {
            formatTheDate(value) {
                if (value) {
                    return moment(String(value)).format('DD/MM/yyyy');
                }
            },
            toCurrency (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    useGrouping: true,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            },
            toTwoDecimal (value) {
                if (typeof value !== "number") {
                    return value;
                }
                let formatter = new Intl.NumberFormat('en-US', {
                    useGrouping: false,
                    maximumSignificantDigits: 2,
                    minimumFractionDigits: 2
                });
                return formatter.format(value);
            }
        },
        computed: {
            formTitle() {
                return this.editedIndex === -1 ? "Nuevo Evento" : "Editar Evento";
            },
            places() {
                let listPlaces = [];
                if (this.placesList) {
                    listPlaces = this.placesList.map(entry => {
                        const PlaceName = entry.place_name.length > this.placeNameLimit
                            ? entry.place_name.slice(0, this.placeNameLimit) + '...'
                            : entry.place_name;
                        console.log('PlaceName: ', PlaceName);
                        return Object.assign({}, entry);
                    });
                }
                return listPlaces;
            },
            computedStartDateFormatted() {
                return this.editedItem.start_date ? this.formatDate(this.editedItem.start_date) : '';
            },
            computedFinishDateFormatted() {
                return this.editedItem.finish_date ? this.formatDate(this.editedItem.finish_date) : '';
            }
        },
        watch: {
            dialog(val) {
                val || this.close();
            },
            dialogDelete(val) {
                val || this.closeDelete();
            },
            searchQuery(val) {
                if (this.queryReady(val)) {
                    this.searchPlaces(val);
                }
            }
        },
        methods: {
            formatDate(value) {
                if (!value) return null;
                let theDate = value.toString().substr(0, 10);
                const [year, month, day] = theDate.split('-')
                return `${day}/${month}/${year}`;
            },
            parseDate(date) {
                if (!date) return null;
                let theDate = date.toString().substr(0, 10);
                const [month, day, year] = theDate.split('/');
                return `${day.padStart(2, '0')}/${month.padStart(2, '0')}/${year}`;
            },
            queryReady(val) {
                return val !== null && val !== undefined && val !== "";
            },
            onClear() {
                this.$emit("input", null);
                this.$emit("clear");
            },
            async searchPlaces(query) {
                // console.log('val:', val);
                if (query === null || query.length === 0) return;
                // Items have already been loaded
                let url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?types=address&access_token=${this.mapBoxApiKey}`;
                this.loadingPlaces = true;
                // Lazily load input items
                fetch(url)
                    .then(res => res.json())
                    .then(res => {
                        // console.log('res: ', res);
                        const {query, features} = res;
                        this.query = query;
                        this.placesList = features;
                        // console.log('places: ', this.places);
                    })
                    .catch(err => {
                        console.log('Error ejecutando API: ', err);
                    })
                    .finally(() => (this.loadingPlaces = false));
            },
            getSelectedItem(item) {
                // console.log('Item selected: ', item);
                this.selectedPlace = Object.assign({}, item);
            },
            getEvents() {
                this.loadingData = true;
                // Catalogo de Eventos...
                eventService.getViewList().then(record => {
                    this.events = record.value;
                }).catch(err=>console.log(err)).finally(()=>this.loadingData = false);
            },
            newItem() {
                this.valid = false;
                this.editedItem = Object.assign({}, this.defaultItem);
            },
            editItem(item) {
                this.editedIndex = this.events.indexOf(item);
                this.editedItem = Object.assign({}, item);
                if (this.editedItem.image_url !== null) {
                    this.imagePreview = this.editedItem.image_url;
                } else {
                    this.imagePreview = null;
                }
                this.imageFile = null;
                this.dialog = true;
            },
            deleteItem(item) {
                this.editedIndex = this.events.indexOf(item);
                this.editedItem = Object.assign({}, item);
                this.dialogDelete = true;
            },
            deleteItemConfirm() {
                this.closeDelete();
                this.loadingSave = true;
                eventService.deleteRecord(this.editedItem.id).then(
                    response => {
                        // console.log("response: ", response);
                        if (response.success) {
                            this.events.splice(this.editedIndex, 1);
                            this.snackText = 'Evento eliminado con éxito.';
                            this.snackBar = true;
                            this.getEvents();
                        } else {
                            this.snackText = 'Error ejecutando API';
                        }
                    }).catch(err => {
                    console.log('Error ejecutando API: ', err);
                }).finally(() => {
                    // console.log("result: ", result);
                    // this.closeDelete();
                    // this.snackBar = true;
                    this.loadingSave = false;
                });
            },
            close() {
                // this.$refs.form.reset();
                this.dialog = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            closeDelete() {
                this.dialogDelete = false;
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem);
                    this.editedIndex = -1;
                });
            },
            save() {
                if (this.imagePreview == null) {
                    // console.log('El formulario no contiene un logo asociado!!');
                    this.messageError = 'El formulario no contiene una imagen asociada. Por favor agrege una!!';
                    this.dialogError = true;
                    return;
                }
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    // console.log('selectedPlace: ', this.selectedPlace);
                    if (this.selectedPlace.center && this.selectedPlace.center.length > 0) {
                        this.editedItem.location = this.selectedPlace.place_name;
                        this.editedItem.map_lng = this.selectedPlace.center[0];
                        this.editedItem.map_lat = this.selectedPlace.center[1];
                    }
                    // console.log('editedItem: ', this.editedItem);
                    this.loadingSave = true;
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        Object.assign(this.events[this.editedIndex], this.editedItem);
                        this.editedItem.investment_amount = this.editItem.investment_amount?this.editItem.investment_amount:0.00;
                        this.editedItem.investment_income = this.editItem.investment_income?this.editItem.investment_income:0.00;
                        eventService.updateRecord(this.editedItem, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getEvents();
                                }
                            }
                        ).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
                    } else {
                        // Agrega el registro por el metodo POST
                        this.events.push(this.editedItem);
                        this.editedItem.investment_amount = this.editItem.investment_amount?this.editItem.investment_amount:0.00;
                        this.editedItem.investment_income = this.editItem.investment_income?this.editItem.investment_income:0.00;
                        eventService.addRecord(this.editedItem, this.imageFile).then(
                            response => {
                                if (response.success) {
                                    this.getEvents();
                                }
                            }
                        ).catch(err=>console.log(err)).finally(()=>this.loadingSave = false);
                    }
                    this.close();
                    this.snackText = 'Registro guardado con éxito.';
                    this.snackBar = true;
                }
            },
            closeDialogError() {
                this.dialogError = false;
                this.messageError = '';
            },
            changePreviewImage() {
                if (this.imageFile) {
                    // console.log('Data imagen: ', this.imageFile, '\n');
                    if (this.imageFile.size > 2000000) {
                        // this.imagePreview = null;
                        return;
                    }
                    let reader = new FileReader();
                    reader.onload = () => {
                        this.imagePreview = reader.result;
                        // console.log('Preview imagen: ',this.imagePreview, '\n');
                    };
                    reader.readAsDataURL(this.imageFile);
                    // console.log('Imagen actualizada: ', this.imageFile.name);
                    this.snackText = 'Imágen de la sección cargada con éxito...';
                    this.snackBar = true;
                }
            },
            /* Para administrar los documentos */
            loadListDocuments(item) {
                this.selectedPDFFiles = [];
                this.pdfFiles = [];
                // Carga los archivos PDF...
                eventService.getRecord(item.id).then(fetch_data => {
                    this.editedItem = Object.assign({}, fetch_data.value);
                });
                if (!this.dialogDocumentsPDF) this.dialogDocumentsPDF = true;
            },
            removeChipPDF(index) {
                this.pdfFiles.splice(index, 1);
                this.selectedPDFFiles = [
                    ...this.pdfFiles,
                ];
            },
            inputPDFChanged() {
                this.pdfFiles = [
                    ...this.selectedPDFFiles,
                ];
            },
            uploadDocuments() {
                // console.log('id del record: ', this.editedItem.id);
                let errorCount = 0;
                for (let i = 0; i < this.pdfFiles.length; i++) {
                    let reader = new FileReader();
                    reader.onload = () => {
                        // this.pdfPreview = reader.result;
                        // console.log('reader.result: ', reader.result);
                    };
                    // console.log('this.pdfFiles[]: ', this.pdfFiles[i]);
                    reader.readAsDataURL(this.pdfFiles[i]);
                    eventService.uploadDocument(this.editedItem.id, -1, this.pdfFiles[i]).then(result => {
                        if (result.success) {
                            this.loadListDocuments(this.editedItem);
                        } else {
                            errorCount++;
                        }
                    });
                }
                this.snackText = (errorCount > 0) ?
                    'Un error impidió subir al menos un documento al servidor' :
                    'Documento subido al servidor. Lista actualizada';
                this.snackBar = true;
            },
            deleteDocument(item) {
                // Elimina la imagen seleccionada desde la bd y del servidor...
                this.currentDocument = Object.assign({}, item);
                this.dialogDeleteDocument = true;
            },
            deleteItemConfirmDocument() {
                // console.log('Current document: ', this.currentDocument);
                eventService.deleteDocument(this.currentDocument.project_id, this.currentDocument.id).then(result => {
                    this.closeDeleteDocument();
                    if (result.success) {
                        this.snackText = 'Documento eliminado desde la lista';
                        this.loadListDocuments(this.editedItem);
                    } else {
                        this.snackText = 'Un error impidió eliminar el documento desde la lista';
                    }
                    this.snackBar = true;
                    this.dialogDeleteDocument = false;
                });
            },
            closeDeleteDocument() {
                this.dialogDeleteDocument = false;
            },
            openPDF(document) {
                //  let baseURL = this.$store.getters.getBaseURL;
                let urlPdf = document.url; // `${baseURL}/view-pdf`;
                window.open(urlPdf, "_blank");
            },
        },
        mounted() {
            this.events = [];
            this.getEvents();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>